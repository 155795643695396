import React from "react";
import Layout from '../components/layout'
import Hero from "../components/hero";
import HeroImageHr from '../images/reg-lr.jpg'
import HeroImage from "../images/reg-lr.jpg";

let hasAddedZola = false

export default class extends React.Component {
  componentDidMount() {
    console.log('hi')
    if (!hasAddedZola) {
      const i = document.getElementById('zola-wjs')
      const s = document.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.innerHTML = '!function(e,t,n){var s,a=e.getElementsByTagName(t)[0];e.getElementById(n)||(s=e.createElement(t),s.id=n,s.async=!0,s.src="https://widget.zola.com/js/widget.js",a.parentNode.insertBefore(s,a));a.parentNode.removeChild(s)}(document,"script","zola-wjs");'
      document.body.appendChild(s)
    }
  }
  render() {
    return (
      <Layout hero={<Hero styleProps={{backgroundPosition: 'top center'}} img={HeroImageHr} className="desktop-only" />}>
        <Hero className="mobile-only" img={HeroImage} styleProps={{backgroundPosition: 'top center'}} />
        <div>
          <a className="zola-registry-embed" href="www.zola.com/registry/krystalandfrank" data-registry-key="krystalandfrank">Our Zola Wedding Registry</a>
        </div>
      </Layout>
    )
  }
}
